import BasePlugin from '../BasePlugin'
import ImportSolutionCmp from './ImportSolutionCmp'

export default class ImportSolution extends BasePlugin {
  async execute () {
    let solutionId = this.context.getModel().id
    const h = this.context.$createElement
    this.context.$msgbox({
      title: 'Выберите файл для загрузки',
      message: h('p', { key: Math.random() }, [
        h(ImportSolutionCmp, { props: { model: solutionId, context: this.context } })
      ]),
      showCancelButton: false,
      showConfirmButton: false
    })
  }
}
