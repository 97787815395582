<template>
  <div class="container">
    <div class="a_file">
      <input type="file" ref="file" @change="handleFileUpload" style="display: none"/>
      <div class="container_title">
        <el-button type="primary" size="small" @click="$refs.file.click()">Выберите файл</el-button>
        <div class="a_file_title">{{ this.title }}</div>
      </div>
    </div>
    <div style="text-align: right">
      <el-button size="small" type="primary" v-on:click="submitFile()">Загрузить</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['model', 'context'],
  data () {
    return {
      file: '',
      local: this.model,
      title: '',
      localContext: this.context,
    }
  },
  watch: {
    local () {
      this.model = this.local
      this.context = this.localContext
    },
    model () {
      this.local = this.model
      this.localContext = this.context
    }
  },
  methods: {
    submitFile () {
      let me = this.context
      let formData = new FormData()
      formData.append('file', this.file)
      let obj = `{"constant_fields": [{"field_id":4743,"value":${this.local},"is_key":true}]}`
      formData.append('payload', obj)
      this.$http.post(`${this.$config.api}/etleditor/import/100`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((response) => {
        let result = JSON.parse(response.data.result)
        let text
        if (result.errors.length === 0 || result.not_loaded === 0) {
          text = 'Проект решения успешно загружен'
        } else if (result.inserted > 0 && (result.erros.length > 0 || result.not_loaded > 0)) {
          text = 'Обнаружена ошибка! Проверьте корректность заполняемых данных и повторите попытку загрузки.'
        } else {
          text = 'Обнаружена ошибка! Проверьте корректность заполняемых данных и повторите попытку загрузки.'
        }
        me.$msgbox.close()
        me.$msgbox({
          type: 'info',
          message: text
        })
      })
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
      this.title = this.file.name
    }
  }
}
</script>
<style>
  .a_file {
    height: 100%;
    flex-direction: column;
    /* display: inline-block; */
  }
  .a_file__title {
    width: 100%;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: #1D242B;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .a_file .container_title {
    display: inline-block;
    background: white;
    display: flex;
    align-items: baseline;
    flex-basis: 100%;
    flex-direction: column;
  }
  .a_file .view {
    background: #6E7B87;
    box-shadow: 0 0 8px 0 rgba(232,237,250,.6), 0 2px 4px 0 rgba(232,237,250,.5);
    /* width: 75%; */
    overflow: auto;
    border-radius: 8px;
    display: flex;
    flex: 1 1 auto;
    position: relative;
  }
  .a_file .container_view {
    display: flex;
    flex: auto;
    max-height: calc(100% - 52px);
  }
  .a_file .container_list {
    box-sizing: border-box;
    background: white;
    padding: 10px;
    flex: none;
    overflow-y: auto;
  }
  .a_file .list_items {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }
  .a_file .active_file {
    background: #EEF0F1;
  }
  .a_file .active_file .remove_file {
    display: inline-block;
  }
  .a_file .file_listing {
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 3px;
    padding: 5px 15px;
    padding-left: 5px;
    flex: none;
    /* width: 84%; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .a_file .file_listing:hover{
    background: #EEF0F1;
  }
  .a_file .remove_file {
    display: none;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    background: #EEF0F1;
    padding-left: 5px;
    width: 18px;
    right: 8px;
  }
  .a_file .file_listing:hover .remove_file {
    display: inline-block;
  }
  .a_file .preview_image {
    /*width: 100%;*/
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: auto;
  }
  .a_file .preview_pdf {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: auto;
  }
  .a_file .preview_doc {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: auto;
  }
  .a_file .preview_text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .a_file .preview_text span {
    color: white;
    font-size: 18px;
  }
  .a_file .download_icon {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 20px;
    bottom: 20px;
    background: rgba(37, 47, 55, 0.6);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .a_file .download_icon i {
    font-size: 28px;
    color: white;
  }
</style>
